.footer{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding-bottom: 2rem;
}

.footer .logo{
    width: 35vw;
    max-width: 150px;
    display: flex;
    margin: 2rem auto 1.5rem;
}

.footer .rubicat{
    display: flex;
    justify-content: center;
    padding: 1.5rem 0 0;
}

.footer .rubicat .texto{
    font-size: 2.5vh;
    color: #004E6F;
    font-weight: 500;
}

.footer .rubicat .img{
    width: 150px;
    margin-left: 1rem;
    margin-top: -1.5rem;
}

.footer .contacto{
    margin-top: 1.35rem;
}

.footer .contacto .texto{
    color: #004E6F;
    font-weight: 500;
    font-size: 2.5vh;
} 

.footer .contacto .mail{
    width: 10vw;
    margin: 0 0.5rem;
}

.footer .contacto a{
    text-decoration: none;
    color: #004E6F;
    font-weight: 700;
    font-size:1REM;
}

.footer .contacto a:hover{
    color: #1CA4CB;
}

@media (min-width: 768px){
    .footer {
        flex-direction: row;
        justify-content: space-around;
        padding-bottom: 1rem;
    }

    .footer .logo{
        width: 22vw;
        margin-top: 2.5rem;
    }

    .footer .rubicat{
        padding: 3.5rem 0 0 3rem;
    }
    
    .footer .rubicat .texto{
        font-size: 3vh;
        color: #004E6F;
        font-weight: 500;
        margin-top: 1rem;
    } 
    
    .footer .rubicat .img{
        width: 150px;
        margin-left: 1rem;
        margin-top: 0rem;
    }

    .footer .contacto{
        padding-top: 3.25rem;
    }

    .footer .contacto .mail{
        width: 40px;
        margin: 0 0.5rem;
    }
    

}
