.cards .titulo {
    position: relative; /* Necesario para posicionar el pseudo-elemento correctamente */
    text-align: center;
    margin: 0.75rem 1rem 1rem;
    font-weight: 700;
    color: inherit; /* Asegura que el texto herede el color del elemento padre */
    
}

.cards .titulo .subrayado{
    position: relative; /* Necesario para posicionar el pseudo-elemento correctamente */
    color: inherit;
}

.cards .titulo .subrayado::after{
        content: "";
        position: absolute;
        bottom: -5px; /* Ajusta esto para que coincida con la altura del subrayado */
        left: 50%;
        transform: translateX(-50%) skewX(-25deg); /* Inclina el subrayado */
        width: 20vw; /* Asegura que el subrayado cubra todo el texto */
        height: 7px; /* Ajusta esto para cambiar la espesor del subrayado */
        background-color: rgba(0,78,111,1); /* Color del subrayado */
        border: solid 1px rgba(0,78,111,1);
        border-color: rgba(0,78,111,1) transparent transparent transparent;
        border-radius: 50%/100px 100px 0 0;
}

.cards .container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    align-items: center;
}

.cards .container .tarjeta{
    margin: 1rem;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

.cards .container .tarjeta .img{
    width: 80vw;
    border-radius: 13px 13px 0 0;
}

.cards .container .tarjeta .textos{
    width: 80vw;
    display: flex;
    flex-direction: column;
    margin: auto;
    border-radius: 0 0 13px 13px;
    text-align: left;
    padding: 1rem;
    padding-left: 1.2rem;
    background-color: #fff;
}

.cards h2{
    font-weight: 600;
}

.cards .container .tarjeta .textos .containerBoton{
    display: flex;
    justify-content: baseline;
    cursor: pointer;
}

.cards a{
    text-decoration: none;
    color: #004E6F;
}

.cards a:hover{
    color: #1CA4CB;
}

.cards .container .tarjeta .textos .boton{
    font-weight: 700;
    font-size: 1.25rem;
}

.cards .container .tarjeta .textos .flecha{
    width: 3rem;
    margin-left: 0.75rem;
    margin-top: -0.25rem;
}

@media (min-width: 768px){
    .cards .titulo .subrayado::after{
        transform: translateX(-50%) skewX(-25deg); /* Inclina el subrayado */
        width: 12vw; /* Asegura que el subrayado cubra todo el texto */
        height: 7px; /* Ajusta esto para cambiar la espesor del subrayado */
}
}

@media (min-width: 1024px){
    .cards .titulo{
        text-align: center;
        margin: 5rem 1rem 3rem;
        font-weight: 700;
        font-size: 2.5rem;
    }

    .cards .titulo .subrayado::after{
        transform: translateX(-50%) skewX(-25deg); /* Inclina el subrayado */
        width: 9vw; /* Asegura que el subrayado cubra todo el texto */
        height: 7px; /* Ajusta esto para cambiar la espesor del subrayado */
}
    
    .cards .container{
        display: flex;
        flex-direction: row;
    }

    .cards .container .tarjeta .img,
    .cards .container .tarjeta .textos{
        width: 40vw;
    }

    .cards .container .tarjeta .textos{
        padding: 1rem 2rem;
        font-size: 1.25rem;
    }
}

@media (min-width: 1440px){

    .cards .titulo .subrayado::after{
        width: 5.5vw; /* Asegura que el subrayado cubra todo el texto */
        height: 7px; /* Ajusta esto para cambiar la espesor del subrayado */
}

}