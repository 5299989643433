.bannerMobile{
    padding: 1.5rem 0 3rem;
}

.bannerMobile .img{
    width: 85vw;
    border-radius: 5%;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

.bannerDesktop{
    display: none;
}

@media (min-width: 1024px){
    .bannerMobile{
        display: none;
    }

    .bannerDesktop{
        display: flex;
        margin: 0 auto ;
        justify-content: center;
        align-items: center;
        padding-top: 2rem;
    }

    .bannerDesktop .img{
        width: 85vw;
        border-radius: 12px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
    }
}