#Navbar{
    background-color: #fff!important;
    padding-bottom: 1.5rem;
}

#Navbar .logo{
    width: 35vw;
    max-width: 150px;
    padding: 1rem;
}

#Navbar .links{
    font-weight: 600;
    color: #004E6F!important;
    padding: 0.35rem 2rem;
    font-size: 1.25rem;
    text-transform: uppercase;
    position: relative; /* Necesario para posicionar el pseudo-elemento correctamente */
    color: inherit; /* Asegura que el texto herede el color del elemento padre */
}

#Navbar .links::after {
    content: "";
    position: absolute;
    bottom: 0px; /* Ajusta esto para que coincida con la altura del subrayado */
    left: 50%;
    transform: translateX(-50%) skewX(-25deg); /* Inclina el subrayado */
    width: 24vw; /* Asegura que el subrayado cubra todo el texto */
    height: 7px; /* Ajusta esto para cambiar la espesor del subrayado */
    background-color: rgba(0,78,111,1); /* Color del subrayado */
}

#Navbar .links:hover{
    font-weight: 900;
    color: #1CA4CB!important;
}


/* #Navbar .links:hover{
    color: #004E6F;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 900;
}

 */
@media(min-width: 768px){
    #Navbar .logo{
        width: 18vw;
    }

    #Navbar .links::after {
        bottom: 0px; /* Ajusta esto para que coincida con la altura del subrayado */
        left: 50%;
        transform: translateX(-50%) skewX(-25deg); /* Inclina el subrayado */
        width: 17vw; /* Asegura que el subrayado cubra todo el texto */
        height: 6px; /* Ajusta esto para cambiar la espesor del subrayado */
        background-color: rgba(0,78,111,1); /* Color del subrayado */
    }
}

@media(min-width: 1024px) {

#Navbar{
    padding-bottom: 0rem;
}

    #Navbar .logo{
        width: 10vw;
    }

    #Navbar .links::after {
        left: 50%;
        transform: translateX(-50%) skewX(-25deg); /* Inclina el subrayado */
        width: 8vw; /* Asegura que el subrayado cubra todo el texto */
        height: 6px; /* Ajusta esto para cambiar la espesor del subrayado */
        background-color: rgba(0,78,111,1); /* Color del subrayado */
        border: solid 1px rgba(0,78,111,1);
        border-color: rgba(0,78,111,1) transparent transparent transparent;
        border-radius: 50%/100px 100px 0 0;
    }
}

@media (min-width: 1440px){
    #Navbar .links::after {
        width: 5vw; /* Asegura que el subrayado cubra todo el texto */
        height: 6px; /* Ajusta esto para cambiar la espesor del subrayado */
        
    }
}

