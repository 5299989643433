main{
    background-color: #eeeeee;
    padding-bottom: 4rem;
    padding-top: 2rem;
}

@media (min-width: 1024px){
    main{
        padding: 2rem 0 5rem;
    }
}