@font-face {
  font-family: 'Franklin';
  src: url('../public/Franklin.ttf');
}

.App {
  text-align: center;
  font-family: 'Franklin';
}


